._19ctf230 {
  flex-wrap: wrap;
}
._19ctf232 {
  display: flex;
}
._19ctf233 {
  display: inline-flex;
}
._19ctf234 {
  flex-direction: row;
}
._19ctf235 {
  flex-direction: column;
}
._19ctf236 {
  flex-direction: row-reverse;
}
._19ctf237 {
  flex-direction: column-reverse;
}
._19ctf238 {
  align-items: center;
}
._19ctf239 {
  align-items: flex-start;
}
._19ctf23a {
  align-items: flex-end;
}
._19ctf23b {
  align-items: stretch;
}
._19ctf23c {
  align-items: baseline;
}
._19ctf23d {
  justify-items: center;
}
._19ctf23e {
  justify-items: flex-start;
}
._19ctf23f {
  justify-items: flex-end;
}
._19ctf23g {
  justify-items: stretch;
}
._19ctf23h {
  justify-items: baseline;
}
._19ctf23i {
  justify-content: center;
}
._19ctf23j {
  justify-content: flex-start;
}
._19ctf23k {
  justify-content: flex-end;
}
._19ctf23l {
  justify-content: space-between;
}
._19ctf23m {
  justify-content: space-around;
}
._19ctf23n {
  justify-content: space-evenly;
}
._19ctf23o {
  padding: 0.5rem 1rem;
}
._19ctf23p {
  padding: 0.5rem 0.5rem;
}
._19ctf23q {
  padding: 1.5rem;
}
._19ctf23r {
  padding: 0;
}
._19ctf23s {
  gap: 0;
}
._19ctf23t {
  gap: 0.625rem;
}
._19ctf23u {
  gap: 1rem;
}
._19ctf23v {
  gap: 2rem;
}
._19ctf23x {
  flex-wrap: wrap;
}
._19ctf23y {
  flex-wrap: nowrap;
}
._19ctf23z {
  width: 100%;
}
._19ctf2310 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1264px;
}
._19ctf230 > * {
  flex-grow: 1;
}
.oloywp0 {
  --oloywp1: #282831;
  --oloywp2: #ffffff;
  --oloywp3: #cecece;
  --oloywp4: #EBEBFE;
  --oloywp5: #4B4B52;
  --oloywp6: #f9617b;
  --oloywp7: #FB8195;
  --oloywp8: #c0c0c5;
  --oloywp9: #000000;
  --oloywpa: #f4f8fb;
  --oloywpb: #393a47;
  --oloywpc: #343541;
  --oloywpd: #738b94;
  --oloywpe: #212129;
  --oloywpf: #ffffff;
  --oloywpg: #fefefe;
  --oloywph: #FAFAFF;
  --oloywpi: #f9617b;
  --oloywpj: #FB8195;
  --oloywpk: #ff5b77;
  --oloywpl: #dcdcdc;
  --oloywpm: #e5e5ef;
  --oloywpn: #f5f5f5;
  --oloywpo: #f5f5fa;
  --oloywpp: #c6c6d1;
  --oloywpq: #5d5e6c;
  --oloywpr: #e65a71;
  --oloywps: #73a55d;
  --oloywpt: #FAFAFF;
  --oloywpu: #333340;
  --oloywpv: #4B4B52;
  --oloywpw: #EBEBFE;
  --oloywpx: 0px;
  --oloywpy: 8px;
  --oloywpz: 12px;
  --oloywp10: 16px;
  --oloywp11: 20px;
  --oloywp12: 24px;
  --oloywp13: 28px;
  --oloywp14: 40px;
  --oloywp15: 52px;
  --oloywp16: 100px;
  --oloywp17: 100;
  --oloywp18: 200;
  --oloywp19: 88px;
  --oloywp1a: 1264px;
  --oloywp1b: 0.3em;
}
.v668070 {
  margin: 0;
  transition: opacity 0.2s ease-out,visibility 0.2s ease-out;
  color: inherit;
}
.v668071 {
  color: var(--oloywpm);
}
.v668072 {
  color: var(--oloywpe);
}
.v668073 {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.5px;
}
.v668074 {
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.9px;
}
.v668075 {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.5px;
}
.v668076 {
  font-size: clamp(1.75rem, 4vw, 2rem);
  line-height: 46px;
}
.v668077 {
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 1439px) {
  .v668073 {
    font-size: max(2.9166666666666665vw, 59px);
    line-height: max(3.3333333333333335vw, 72px);
  }
  .v668074 {
    font-size: max(3.888888888888889vw, 59px);
    line-height: max(5vw, 72px);
  }
  .v668075 {
    font-size: max(2.9166666666666665vw, 59px);
    line-height: max(3.3333333333333335vw, 72px);
  }
  .v668077 {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  .v668073 {
    font-size: max(4.947916666666666vw, 40px);
    line-height: max(6.25vw, 52px);
  }
  .v668074 {
    font-size: max(6.510416666666667vw, 40px);
    line-height: max(8.333333333333332vw, 52px);
  }
  .v668075 {
    font-size: max(4.947916666666666vw, 40px);
    line-height: max(6.25vw, 52px);
  }
}
.gwqokm0 {
  text-decoration: none;
}
._14d1rsc0 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 8px;
  will-change: transform, opacity;
  transition: transform 0.2s ease-out,color 0.2s ease-out,background-color 0.2s ease-out,box-shadow 0.2s ease-out;
  cursor: pointer;
  background-color: var(--oloywpi);
  color: var(--oloywpf);
  box-shadow: 0 4px 12px 0 rgba(249, 97, 123, 0.2);
}
._14d1rsc0:hover {
  background-color: var(--oloywpj);
}
._14d1rsc0:active {
  background-color: var(--oloywpr);
  transform: none;
  box-shadow: none;
}
._14d1rsc1 {
  padding: 8px 16px;
}
._14d1rsc2 {
  padding: 12px 16px;
}
._14d1rsc3 {
  padding: 20px 24px;
}
._14d1rsc4 {
  background-color: var(--oloywpf);
  color: var(--oloywpi);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}
._14d1rsc4:hover {
  background-color: var(--oloywpf);
  color: var(--oloywpj);
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.15);
}
._14d1rsc4:active {
  background-color: var(--oloywpf);
  color: var(--oloywpi);
  box-shadow: none;
}
._14d1rsc6 {
  background-color: transparent;
  color: var(--oloywpi);
  box-shadow: none;
}
._14d1rsc6:hover {
  opacity: 0.8;
  background-color: transparent;
  box-shadow: none;
}
._14d1rsc8 {
  box-shadow: 0px 12px 40px 0px rgba(249, 97, 123, 0.3);
}
._14d1rsc9 {
  border-radius: 6px;
  background-color: var(--oloywpn);
  color: var(--oloywpe);
  box-shadow: none;
}
._14d1rsc9:hover {
  background-color: var(--oloywpj);
  color: var(--oloywpf);
}
._14d1rsca {
  background-color: var(--oloywpf);
  color: var(--oloywpi);
  box-shadow: inset 0 0 0 2px var(--oloywpi);
}
._14d1rsca:hover {
  box-shadow: inset 0 0 0 2px var(--oloywpj);
  color: var(--oloywpj);
  background-color: var(--oloywpf);
}
._14d1rsca:active {
  color: var(--oloywpf);
  box-shadow: none;
}
._14d1rscb {
  background-color: var(--oloywpf);
  color: var(--oloywpi);
  box-shadow: inset 0 0 0 2px var(--oloywpi);
}
._14d1rscb:hover {
  background-color: var(--oloywpf);
  box-shadow: inset 0 0 0 2px var(--oloywpj);
  color: var(--oloywpj);
}
._14d1rscb:active {
  color: var(--oloywpf);
  box-shadow: none;
}
._14d1rscc {
  cursor: not-allowed;
  background-color: var(--oloywpp);
  color: var(--oloywpf);
  box-shadow: none;
}
._14d1rscc:hover {
  background-color: var(--oloywpp);
  transform: none;
  box-shadow: none;
}
._14d1rscc:active {
  background-color: var(--oloywpp);
}
._14d1rsce {
  text-align: center;
  color: inherit;
}
._14d1rscf {
  font-size: 15px;
  line-height: 18px;
}
._14d1rscg {
  font-size: 20px;
  line-height: 24px;
}
._14d1rsci {
  font-weight: 400;
}
._14d1rscj {
  font-weight: 700;
}
._14d1rscl {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  display: flex;
  align-items: center;
}
._14d1rscm {
  margin-right: 4px;
}
._14d1rscn {
  margin-right: 8px;
}
._14d1rsco {
  margin-left: 0;
  margin-right: 4px;
}
._14d1rscp {
  margin-left: 8px;
  margin-right: 0;
}
._14d1rsck svg, ._14d1rsck img {
  display: block;
  width: inherit;
  height: inherit;
}
@media (max-width: 767px) {
  ._14d1rsc0 {
    padding: 12px 20px;
  }
  ._14d1rscl {
    width: 22px;
    height: 22px;
  }
}
._167sh8x0 {
  display: flex;
  align-items: center;
  width: max-content;
  color: var(--oloywpi);
  transition: color 0.2s ease-out;
}
._167sh8x0:hover {
  color: var(--oloywp7);
}
._167sh8x1 {
  color: inherit;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
}
._167sh8x2 {
  height: 22px;
  margin-left: 8px;
  transition: transform 0.2s ease-out;
  will-change: transform;
}
@media (max-width: 767px) {
  ._167sh8x2 {
    margin-left: 12px;
  }
}
._1b032e80 {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  z-index: 100;
  top: 0;
  padding: 1rem 0rem;
  height: var(--oloywp19);
  transition: background-color 0.2s ease-out;
  width: 100%;
  background-color: transparent;
  color: var(--oloywpe);
}
._1b032e81 {
  background-color: transparent;
}
._1b032e82 {
  background-color: var(--oloywpf);
}
._1b032e83 {
  max-width: var(--oloywp1a);
  width: 100%;
  padding: 0 16px;
}
._1b032e84 {
  display: flex;
  align-items: center;
  z-index: 1;
}
._1b032e85 {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  color: var(--oloywpi);
}
._1b032e86 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
._1b032e84:hover ._1b032e86 {
  transition: color 0.2s ease-out;
  color: var(--oloywpj);
}
._1b032e84:hover ._1b032e85 {
  transition: opacity 0.2s ease-out;
  opacity: 0.8;
}
._1b032e87 {
  display: flex;
  align-items: center;
  gap: 20px;
}
._1b032e88 {
  flex-wrap: nowrap;
  white-space: nowrap;
}
._1b032e89 {
  position: relative;
  padding: 12px;
  color: inherit;
  white-space: nowrap;
  transition: color 0.2s ease-out;
}
._1b032e89:active {
  color: var(--oloywpi);
}
._1b032e89:hover {
  color: var(--oloywpi);
}
._1b032e8a {
  color: var(--oloywpi);
}
._1b032e8a:after {
  opacity: 1;
  transform: translate(-50%, 0);
}
._1b032e8b {
  line-height: 1;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: inherit;
}
._1b032e8c {
  font-weight: 700;
}
._1b032e8e {
  display: none;
  width: 2px;
  height: 24px;
  background-color: var(--oloywpl);
}
._1b032e8f {
  padding: 12px;
}
._1b032e8g {
  display: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
}
._1b032e8h {
  color: var(--oloywpe);
}
._1b032e8i {
  color: var(--oloywpm);
}
@media (max-width: 1439px) {
  ._1b032e80 {
    padding: 24px 0;
  }
}
@media (max-width: 1023px) {
  ._1b032e85 {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }
  ._1b032e86 {
    font-size: 16px;
    line-height: 24px;
  }
  ._1b032e87 {
    gap: 8px;
  }
  ._1b032e89 {
    padding: 4px 6px;
    margin: 0 6px;
  }
}
@media (max-width: 839px) {
  ._1b032e80 {
    padding: 10px 0;
  }
  ._1b032e85 {
    width: 32px;
    height: 32px;
  }
  ._1b032e87 {
    gap: 8px;
  }
  ._1b032e89 {
    display: none;
  }
  ._1b032e8b {
    font-size: 14px;
  }
  ._1b032e8d {
    display: none;
  }
  ._1b032e8f {
    display: none;
  }
  ._1b032e8g {
    display: block;
    width: 44px;
    height: 44px;
    padding: 6px;
    margin-left: 6px;
    margin-right: -6px;
    background-color: transparent;
  }
  ._1b032e8j {
    display: block;
  }
}
._62yspj0 {
  position: relative;
}
._62yspj1 {
  padding: 12px;
  padding-right: 0;
}
._62yspj1:hover {
  cursor: pointer;
  fill: var(--oloywpi);
  color: var(--oloywpi);
}
._62yspj2 {
  line-height: 1;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: inherit;
  transition: color 0.2s ease-out;
  border-radius: 4px;
}
._62yspj3 {
  background-color: var(--oloywpn);
}
._62yspj4 {
  margin-left: 5px;
  transition: fill, transform 0.2s ease-out;
}
._62yspj5 {
  transform: rotate(180deg);
}
._62yspj6 {
  position: absolute;
  display: grid;
  visibility: hidden;
  grid-template-columns: repeat(2, 1fr);
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 8px;
  padding: 15px;
  top: 50px;
  background-color: var(--oloywpf);
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.15), 0px 20px 100px rgba(0, 0, 0, 0.12);
  transition: opacity 0.2s ease-out;
}
._62yspj7 {
  opacity: 1;
  display: grid;
  visibility: visible;
  width: auto;
  height: auto;
  grid-template-columns: repeat(2, 1fr);
}
._62yspj8 {
  border-radius: 4px;
  padding: 12px;
}
._62yspj8:hover {
  background-color: var(--oloywpn);
  cursor: pointer;
}
._62yspj9 {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  line-height: 0.9;
  margin-bottom: 5px;
  transition: color 0.2s ease-out;
}
._62yspj8:hover ._62yspj9 {
  color: var(--oloywpi);
}
._62yspja {
  font-size: 14px;
  white-space: nowrap;
  font-weight: 400;
  color: var(--oloywpv);
  line-height: 17px;
}
._62yspjb {
  display: flex;
  width: 25px;
}
._62yspjc {
  transition: fill 0.2s ease-out;
  fill: var(--oloywpe);
}
._62yspj8:hover ._62yspjc {
  fill: var(--oloywpi);
}
@media (max-width: 767px) {
  ._62yspj1 {
    padding-left: 0;
  }
  ._62yspj2 {
    font-weight: 400;
  }
  ._62yspj6 {
    display: none;
    position: relative;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    top: 0;
    padding-top: 0;
    transform: translateY(-100%);
    transition: transform 0.2s ease-out;
  }
  ._62yspj7 {
    display: block;
    transform: translateY(0);
  }
  ._62yspj9 {
    font-weight: 400;
  }
  ._62yspja {
    display: none;
  }
}
@media (max-width: 1023px) {
  ._62yspj2 {
    font-size: 14px;
  }
}
._1orw42w0 {
  display: none;
}
._1orw42w0[data-mobile-menu-active] {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
._1orw42w1 {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 10px;
  padding-bottom: 27px;
}
._1orw42w2 {
  width: 100%;
  padding: 0 16px;
  padding-top: 12px;
}
._1orw42w3 {
  display: flex;
  align-items: center;
}
._1orw42w4 {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  color: var(--oloywpi);
}
._1orw42w5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
._1orw42w6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
._1orw42w7 {
  padding: 4px 0px;
  margin: 0 6px 24px 0px;
  color: var(--oloywpe);
  transition: color 0.2s ease-out;
}
._1orw42w8 {
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: inherit;
}
._1orw42wb {
  width: 44px;
  height: 44px;
  padding: 6px;
  margin-left: 6px;
  margin-right: -6px;
  background-color: transparent;
  color: var(--oloywpe);
  -webkit-tap-highlight-color: transparent;
  border: none;
}
._1orw42wc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  margin-top: auto;
}
._1orw42wd {
  min-width: 256px;
  margin: 6px;
}
._1orw42we {
  color: var(--oloywpi);
  margin: 6px;
  font-size: 14px;
}
@media (max-width: 839px) {
  ._1orw42w0 {
    display: block;
    position: fixed;
    z-index: var(--oloywp18);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--oloywpf);
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0.2s ease-out,opacity 0.2s ease-out;
  }
  ._1orw42w6 {
    width: 100%;
    padding: 0 16px;
  }
  ._1orw42w9 {
    width: 100%;
  }
  ._1orw42wa {
    display: block;
    width: 100%;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--oloywpn);
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  color: inherit;
  font-family: inherit;
}
body {
  max-width: 100vw;
  scroll-behavior: smooth;
  margin: 0;
  background-color: var(--oloywp1);
  color: var(--oloywp3);
}
html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
a {
  background-color: transparent;
  color: inherit;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
}
svg {
  display: inline-block;
}
ul {
  list-style-position: inside;
}
p {
  line-height: 1.5rem;
}
p a {
  color: var(--oloywp6);
}
b {
  font-weight: bolder;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d9825c';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d9825c {font-family: '__Inter_d9825c', '__Inter_Fallback_d9825c', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, system-ui, sans-serif;font-style: normal
}.__variable_d9825c {--family-sans: '__Inter_d9825c', '__Inter_Fallback_d9825c', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, system-ui, sans-serif
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Serif_4_0b3a9f';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/fe9c7822a307cde8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Serif_4_0b3a9f';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/d179e18aba8dc44e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Source_Serif_4_0b3a9f';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/6cdda5facec49c5b-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Serif_4_0b3a9f';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/7322f9e833156f89-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Serif_4_0b3a9f';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/b3a59244d350cb79-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Serif_4_0b3a9f';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/75d48862b1c48d49-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Serif_4_Fallback_0b3a9f';src: local("Times New Roman");ascent-override: 87.04%;descent-override: 28.14%;line-gap-override: 0.00%;size-adjust: 119.03%
}.__className_0b3a9f {font-family: '__Source_Serif_4_0b3a9f', '__Source_Serif_4_Fallback_0b3a9f', Source Serif Pro, Georgia, Times New Roman, Times, serif;font-style: normal
}.__variable_0b3a9f {--family-serif: '__Source_Serif_4_0b3a9f', '__Source_Serif_4_Fallback_0b3a9f', Source Serif Pro, Georgia, Times New Roman, Times, serif
}

/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/443826f37f277086-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f7dd447b9493d363-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3e815986a00815c7-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bacd1fe82afba745-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b82c0038f5b398f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6e5e69ce8a001790-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/efb821fde00cfc9f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3431245e3323d99c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/af381422237abb74-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e76cf4e32bd7851d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c5bcb36e58e2dd16-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_Mono_190740';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/17d789ad52fa3db3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Ubuntu_Mono_Fallback_190740';src: local("Arial");ascent-override: 73.27%;descent-override: 15.01%;line-gap-override: 0.00%;size-adjust: 113.27%
}.__className_190740 {font-family: '__Ubuntu_Mono_190740', '__Ubuntu_Mono_Fallback_190740', Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;font-style: normal
}.__variable_190740 {--family-mono: '__Ubuntu_Mono_190740', '__Ubuntu_Mono_Fallback_190740', Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace
}

